.testTableHeader{
    color: #282c34;
    background: aqua;
    border-style: dashed;
    border-width: 1px;
}

.testTable{
    color: #282c34;
    background: aquamarine;
    border-style: dashed;
    border-width: 1px;
}