/*.container-fluid {*/
/*    width:100%;*/
/*}*/

body {
    background-color: #282c34;
    color: white;
}

/*.navbar {*/
/*    padding-left: 0;*/
/*}*/

/*hr {*/
/*    margin-top: 0.2rem;*/
/*    margin-bottom: 0.4rem;*/
/*}*/

/*.nav-link {*/
/*    padding-top: 1px;*/
/*    padding-bottom: 1px;*/
/*    padding-left: 1px;*/
/*    padding-right: 1px;*/
/*    font-size: 1rem;*/
/*}*/
