.title {
    padding-top: 0px;
    padding-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 2px;
    font-size: 1.5rem;
}
.sub_title {
    border-left: solid 3px #ccb600;
    padding-left: 8px;
    padding-bottom: 2px;
    border-bottom: solid 1px #aa8600;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 1px;
    font-size: 1.1rem;
}

.detail, .command {
    border-color: black;
    border-style: solid;
    border-radius: 3px;
    border-width: 1px;
    padding: 2px 5px;
    background-color: #383c44;
    color: #dddddd;
    margin-top: 1px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 1px;
    font-size: 1rem;
}

.url {
    background: #383c44;
    padding-left: 8px;
    margin-top: 2px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 2px;
    font-size: 1rem;
}
